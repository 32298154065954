.wrapper {
  padding: 20px
}

body {
  background-color: black;
}

#loading-gif {
  display: block;
  /* margin-left: auto;
  margin-right: auto; */
  width: 5%;
  position: absolute;
  /* top: 50vh;
  right: 50vw; */
  margin: auto;
  position: absolute;
  top: 50%;
  right: 50%;
  /* -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}