--va

* {
    overflow: hidden;
}

#container {
    positioN: absolute;
    width: 100vw;
    top: 0;
    bottom: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    overflow: hidden;
}
#global-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(-180deg, #000000 60%, #9198e5);
}
#red-slice {
    position: absolute;
    background-color: rgb(0, 0, 0);
    /* background-image: url("./img/gradient2.jpg"); */
    background: linear-gradient(45deg, #ffe8e8, #131313);
    background-size: cover;
    z-index: 1;
    /* -webkit-transform: skewY(-3deg);
    -moz-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    -o-transform: skewY(-3deg);
    transform: skewY(-3deg); */
    bottom: 3rem;
    top: 3rem;
    left: 0;
    right: 0;
    /* border: 30px solid rgb(255, 0, 0); */
    overflow: hidden;
    animation-name: slidein;
    -o-animation-name: slidein;
    -moz-animation-name: slidein;
    animation-duration: 1.0s;
    -o-animation-duration: 1.0s;
    -moz-animation-duration: 1.0s;
}
#main-content {
    display: flex;
    z-index: 2;
    bottom: 0;
    justify-content: center;
    justify-self: center;
    height: 100vh;
    overflow: hidden;
}
.login-wrapper {
    display:flex;
    flex-direction:row;
    align-items: center;
    color: white;
    z-index: 2;
    flex: 1;
    overflow: hidden;
}
#side-content {
    flex: 3 1;
    color: white;
    background-color: black;
    background: linear-gradient(-185deg, #000000 60%, #9198e5);
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}
#dashboard-image {
    margin-top: 5vh;
    display: flex;
    overflow: hidden;
    width: 45vw;
    border-radius: 6px;
    box-shadow: 5px 5px 10px #3482ff, 15px 15px 25px #ff0565
}
#nn-description {
    display: flex;
    font-size: 3rem;
}
#login-form {
    z-index: 3;
    flex-direction:column;
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 1.4em;
    padding-bottom: 8rem;
    display: flex;
}
h1 {
    color: white;
    font-size: 2em;
}
#submit-login {
    margin-top: 2rem;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 8px;
    color: white;
    font-size: 0.7em;
    padding: 0.6em;
    transition: all 0.2s ease-out;
}
#submit-login:hover {
    color: black;
    box-shadow: 0 0 10px 5px black;
    border: 2px solid black;
    cursor: pointer;
}
#form-login-username {
    margin: 0;
    padding: 0px;
 }
#form-login-username-text {
   padding: 0px;
   margin: 0;
}

#form-login-password-text {
    margin: 0;
    padding: 0;
}
#form-login-password {
    margin: 0;
    padding: 0;
}

#login-password-input, #login-username-input {
    width: 20vw;
    height: 2em;
    margin-bottom: 20px;
    font-size: 0.6em;
    border: 0px;
    border-radius: 5px;
}

/* #logo {
    z-index: 5;
    height: 5rem;
    align-self: center;
    justify-self: center;
    display: flex;
    text-align: center;
} */

/* #logo {
    position: absolute;
    z-index: 5;
    left: 50vw;
    top: 5rem;
    height: 5rem;
} */

#logo {
    position: absolute;
    z-index: 5;
    padding-right: 3vw;
    /* right: 10vw; */
    bottom: 80vh;
    height: 5rem;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    flex: 1;

}

#email-notice {
    color: rgb(241, 255, 234);
}

@keyframes slidein {
    /* 0%   {background-color:red; left:0px; top:0px;}
    25%  {background-color:yellow; left:200px; top:0px;}
    50%  {background-color:blue; left:200px; top:200px;}
    75%  {background-color:green; left:0px; top:200px;}
    100% {background-color:red; left:0px; top:0px;} */
    from {background-position-x: 1000px; background-position-y: -60px;}
    to {background-position-x: 0px; background-position-y: 0px;}
}

@media only screen and (max-width: 2000px) {
    #logo {
        height: 3rem;
    }
    h1 {
        font-size: 1.75em;
    }

    #email-notice {
        font-size: 1em;
    }
}

@media only screen and (max-width: 1200px) {
    #nn-description {
        display: flex;
        font-size: 2rem;
        padding: 10px;
    }
    
    #logo {
        height: 6rem;

    }
    h1 {
        font-size: 1.0em;
    }

    #email-notice {
        font-size: 0.5em;
    }

    #login-password-input, #login-username-input {
        height: 1.5em;
        margin-bottom: 8px;
        font-size: 0.6em;
        border: 0px;
        border-radius: 5px;
    }

    #form-login-username-text {
        padding: 0px;
        margin: 0;
        font-size: 0.6em;
    }
     
     #form-login-password-text {
         margin: 0;
         font-size: 0.6em;
    }

    #submit-login {
        margin-top: 1rem;
        background-color: transparent;
        border: 2px solid white;
        border-radius: 8px;
        color: white;
        font-size: 0.75em;
        padding: 0.5em;
        transition: all 0.2s ease-out;
    }
}

@media only screen and (max-width: 800px) {
    
    #nn-description {
        display: flex;
        font-size: 2vw;
        padding: 10px;
    }
    
    #logo {
        display: none;
    }
    h1 {
        font-size: 1.0em;
    }

    #email-notice {
        font-size: 0.43em;
    }

    #login-password-input, #login-username-input {
        height: 1em;
        margin-bottom: 5px;
        font-size: 0.5em;
        border: 0px;
        border-radius: 5px;
    }

    #form-login-username-text {
        padding: 0px;
        margin: 0;
        font-size: 0.6em;
    }
     
     #form-login-password-text {
         margin: 0;
         font-size: 0.6em;
    }

    #submit-login {
        margin-top: 1rem;
        background-color: transparent;
        border: 2px solid white;
        border-radius: 8px;
        color: white;
        font-size: 0.5em;
        padding: 0.3em;
        transition: all 0.2s ease-out;
    }
}